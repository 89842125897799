import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/index.js";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import vuetify from "./plugins/vuetify";
import VuetifyDialog from 'vuetify-dialog'
// juan
import './scss/main.scss'
// juan

import * as firebase from "firebase";

import { firestorePlugin } from "vuefire";
import DatetimePicker from "vuetify-datetime-picker";
import VueExcelXlsx from "vue-excel-xlsx";

import { initializeApp } from 'firebase/app';

import "firebase/firestore";

// import 'leaflet/dist/leaflet.css';

// new

// import Meta from 'vue-meta'
Vue.use(DatetimePicker);
Vue.use(VueExcelXlsx);
// Vue.use(Meta)
// import { Datetime } from 'vue-datetime'
// import 'vue-datetime/dist/vue-datetime.css'
// Vue.use(Datetime)
// Vue.component('datetime', Datetime)

Vue.use(firestorePlugin);
Vue.use(VuetifyDialog, {

  context: {
    vuetify
  }
})

// Vue.use(Vuetify);

Vue.config.productionTip = false;

const configOptions = {
  apiKey: "AIzaSyAukzvlrg866-YUl0tIrUGDI8lCf0MYaAg",
  authDomain: "panel-53aab.firebaseapp.com",
  databaseURL: "https://panel-53aab.firebaseio.com",
  projectId: "panel-53aab",
  storageBucket: "panel-53aab.appspot.com",
  messagingSenderId: "288872450371",
  appId: "1:288872450371:web:895aa8d4babf9de2c847f6",
};

const app = initializeApp(configOptions);
// const db = getFirestore(app);

const db = firebase.firestore(app);


export { db };

// // Get a list of cities from your database
// async function obtenerPedidos(db) {
//   const listaPedidos = collection(db, "enviarCorreos");
//   const pedidosSnapshot = await getDocs(listaPedidos);
//   const pedidosList = pedidosSnapshot.docs.map((doc) => doc.data());
//   return pedidosList;
// }

// const retiros = obtenerPedidos(db);

// console.log(retiros);

// const messaging = firebase.messaging()

var datosUserGo = {};
var perfilEmpresaGo = {};
var localArrayCecosOwner = [];
var userGo = {};

var promisesFB = [];
firebase.auth().onAuthStateChanged((user) => {
  promisesFB = [];
  var self = this;
  if (user != null && user != undefined) {
    userGo = user;
    // Vue.use(user);
    // console.log(self)
    console.log("Cambió estado, user==true∫ , exito");
    // if (user != null & user.emailVerified == false && router.currentRoute.name != 'verify' && router.currentRoute.name != 'notverified') {
    let tipoUsuario = "";
      
       if( user.email.substr(user.email.length - 11) == "rayoapp.com" ) {
        tipoUsuario="rayo"
        userGo.tipoUsuario = tipoUsuario
        store.dispatch("fetchUser", userGo);
        // router.push({
        //     name: "ingresos",
       
        // });

       } 
      
      //  else if (user.email.substr(user.email.length - 16) == "_epa@rayoapp.com" ) {
      //   tipoUsuario="epa"
      //   userGo.tipoUsuario = tipoUsuario
      //   store.dispatch("fetchUser", userGo);
       
      //   // router.push({
      //   //   // name: "dashboardThor",
      //   //   name: "dashboardThor",
      //   // });
      //  } 
       else
       {
        tipoUsuario="otro"
        userGo.tipoUsuario = tipoUsuario
        store.dispatch("fetchUser", userGo);
       
        // router.push({
       
        //   name: "login",
        // });
       }
    
     

   
  

    // if (

    // 	user.email.substr(user.email.length - 11)!="rayoapp.com"

    // ) {
    // 	// console.log(router)
    // 	// router.push({
    // 	// 	name: "notverified",
    // 	// });
    // 	store.dispatch("fetchUser", userGo);
    // 	router.push({
    // 		// name: "dashboardThor",
    // 		name: "asignacionesWarehouse",
    // 	});
    // } else {

    // 	console.log("user correcto");
    // 	store.dispatch("fetchUser", userGo);

    // 	router.push({
    // 		// name: "dashboardThor",
    // 		name: "warehouse",
    // 	});

    // 	// switch (user.email) {

    // 	// 	case "juanandres@rayoapp.com":
    // 	// 		router.push({
    // 	// 			// name: "dashboardThor",
    // 	// 			name: "warehouse",
    // 	// 		});
    // 	// 		break;

    // 	// 	case "datasocofar@rayoapp.com":
    // 	// 		router.push({
    // 	// 			// name: "dashboardThor",
    // 	// 			name: "dashboard",
    // 	// 		});
    // 	// 		break;
    // 	// 	case "televenta_socofar@rayoapp.com":
    // 	// 		router.push({
    // 	// 			name: "ingreso",
    // 	// 		});
    // 	// 		break;
    // 	// 	case "ecommerce_socofar@rayoapp.com":
    // 	// 		router.push({
    // 	// 			name: "ingreso",
    // 	// 		});
    // 	// 		break;
    // 	// 	case "maicao@rayoapp.com":
    // 	// 		router.push({
    // 	// 			name: "ingreso",
    // 	// 		});
    // 	// 		break;
    // 	// 		default :
    // 	// 		router.push({
    // 	// 			name: "scanner",
    // 	// 		});
    // 	// }
    // }
  } else {
    console.log("no hay user");
    store.dispatch("fetchUser", null);

    router.push({
      // name: "dashboardThor",
      name: "login",
    });
  }
});

Vue.config.productionTip = false;
const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        rayo:'#311eee'
      },
    },
  }
}
// new Vue({
//   Vuetify,
//   store,
//   router,
//   vuetify,
//   render: (h) => h(App),
//   firebase,
// }).$mount("#app");

Vue.use(Vuetify)
new Vue({
  store,
  vuetify: new Vuetify(vuetifyOptions),
  router,
  render: (h) => h(App),
  firebase,
}).$mount("#app");
