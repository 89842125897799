import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import firebase from "firebase";

const Ingresos = () => import("../components/Ingresos.vue");
const Ordenes = () => import("../components/Ordenes.vue");
const Salidas = () => import("../components/Salidas.vue");
const EtiquetasGPSChile = () => import("../components/EtiquetasGPSChile.vue");
const Supply = () => import("../components/Supply.vue");
const Retornos = () => import("../components/Retornos.vue");
const Picking = () => import("../components/Picking.vue");
const Updates = () => import("../components/Updates.vue");
const Mermas = () => import("../components/Mermas.vue");
const Resumen = () => import("../components/Resumen.vue");
const AsignacionesEnWarehouse = () =>
  import("../components/AsignacionesEnWarehouseRutas.vue");
const Devoluciones = () => import("../components/Devoluciones.vue");
const Dashboard = () => import("../components/Dashboard.vue");
const DashboardEpa = () => import("../components/DashboardEpa.vue");
const RecepcionStock = () => import("../components/RecepcionStock.vue");
const RecepcionStockV2 = () => import("../components/RecepcionStockV2.vue");
const RecepcionDevoluciones = () =>
  import("../components/RecepcionDevoluciones.vue");
const PickingFulfillment = () => import("../components/PickingFulfillment.vue");
const Visualizador = () => import("../components/Visualizador.vue");
const LayoutsBodegas = () => import("../components/LayoutsBodegas.vue");
const VisualizadorBeta = () => import("../components/VisualizadorBeta.vue");
const Rutas = () => import("../components/Rutas.vue");
const RetirosClientes = () => import("../components/RetirosClientes.vue");
const Unauthorized = () => import("../views/Unauthorized.vue");
const Multiscan = () => import("../components/Multiscan.vue");
const Login = () => import("../components/Login.vue");
const Tbk = () => import("../components/Tbk.vue");
const Migps = () => import("../components/Migps.vue");
const Fulfillment = () => import("../components/Fulfillment.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
      outIfLoggedin: true,
    },
  },
  {
    path: "/ingresos",
    name: "ingresos",
    component: Ingresos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salidas",
    name: "salidas",
    component: Salidas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/supply",
    name: "supply",
    component: Supply,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ordenes",
    name: "ordenes",
    component: Ordenes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/picking",
    name: "picking",
    component: Picking,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/retornos",
    name: "retornos",
    component: Retornos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/etiquetasGPSChile",
    name: "etiquetasGPSChile",
    component: EtiquetasGPSChile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/updates",
    name: "updates",
    component: Updates,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mermas",
    name: "mermas",
    component: Mermas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resumen",
    name: "resumen",
    component: Resumen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/asignacionesWarehouse",
    name: "asignacionesWarehouse",
    component: AsignacionesEnWarehouse,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/devoluciones",
    name: "devoluciones",
    component: Devoluciones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard-epa",
    name: "dashboard-epa",
    component: DashboardEpa,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visualizador",
    name: "visualizador",
    component: Visualizador,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recepcionStock",
    name: "recepcionStock",
    component: RecepcionStock,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recepcionStockV2",
    name: "recepcionStockV2",
    component: RecepcionStockV2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recepcionDevoluciones",
    name: "recepciondevoluciones",
    component: RecepcionDevoluciones,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/picking-fulfillment",
    name: "pickingFulfillment",
    component: PickingFulfillment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visualizadorBeta",
    name: "visualizadorBeta",
    component: VisualizadorBeta,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/layoutsBodegas",
    name: "layoutsBodegas",
    component: LayoutsBodegas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/rutas",
    name: "rutas",
    component: Rutas,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: Unauthorized,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/retirosMasivos",
    name: "retirosMasivos",
    component: RetirosClientes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/multiscan",
    name: "multiscan",
    component: Multiscan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tbk",
    name: "tbk",
    component: Tbk,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Migps",
    name: "Migps",
    component: Migps,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Fulfillment",
    name: "Fulfillment",
    component: Fulfillment,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("to:" + to.name);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const outIfLoggedin = to.matched.some((record) => record.meta.outIfLoggedin);
  const isAuthenticated = firebase.auth().currentUser;
  console.log(store);
  // var isAuthenticated= store.getters.user.loggedIn
  // console.log(store.getters.user.loggedIn)

  // console.log(isAuthenticated)
  console.log("requieres auth?");
  console.log(requiresAuth);
  console.log("Authenticated??");
  console.log(isAuthenticated);

  // forces the account info completition

  //   if (store != undefined && store != null && store.getters != null && store.getters.user.datosFb!= null  && store.getters.user.datosFb.status=="pending" && router.currentRoute!="/account") {
  //     console.log("dddddd")
  //     //next('/account')
  //   }
  //   else {
  //     console.log("cuenta activa. Continua")
  //   }
  // // forces..

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
  //next()
});

export default router;
